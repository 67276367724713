import React from "react";
import me from "../../images/me.png";

const skills = ["Java", "TypeScript", "ReactJS"];

const AboutMe = () => {
  return (
    <div className="container">
      <div className="flex flex-col items-center justify-center lg:flex-row">
        <div className="rounded-full shadow-2xl">
          <img
            src={me}
            className="h-48 rounded-full sm:h-56"
            alt="Tadeusz Wyrzykowski"
          />
        </div>
        <div className="pt-8 sm:pt-10 lg:pl-8 lg:pt-0">
          <h1 className="text-center font-header text-4xl text-white sm:text-left sm:text-5xl md:text-6xl">
            Hey! 👋 I'm Tadeusz.
          </h1>
          <div>
            {skills.map((skill) => (
              <span className="px-1 rounded-full text-white font-semibold text-sm w-max">
                #{skill}
              </span>
            ))}
          </div>
          <div className="flex flex-col justify-center pt-3 sm:flex-row sm:pt-5 lg:justify-start">
            <div className="flex items-center justify-center pt-5 sm:justify-start sm:pt-0">
              <a href="https://github.com/Shaquu">
                <svg
                  fill="white"
                  viewBox="0 0 16 16"
                  height="28px"
                  width="28px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/in/tadeusz-wyrzykowski/"
                className="pl-4"
              >
                <svg
                  fill="white"
                  viewBox="0 0 16 16"
                  height="28px"
                  width="28px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                </svg>
              </a>
              <a href="mailto:hello@wyrzykowsky.pl" className="pl-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  height="28px"
                  width="28px"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
