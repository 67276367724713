import React from "react";
import AboutMe from "./aboutme/AboutMe";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";

const pages: ({ title: string } & RouteObject)[] = [
  {
    title: "About Me",
    path: "/",
    element: <AboutMe />,
  },
  {
    title: "Projects",
    path: "/projects",
    element: <>Projects</>,
  },
  {
    title: "Contact",
    path: "/contact",
    element: <>Contact</>,
  },
];

const router = createBrowserRouter(pages);

function Index() {
  return (
    <div className="h-screen isolate backdrop-blur-xl bg-gradient-to-br from-violet-500 to-fuchsia-500">
      <main>
        <div className="relative px-6 lg:px-8">
          <div className="mx-auto max-w pt-20 pb-32 sm:pt-48 sm:pb-40">
            <RouterProvider router={router} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Index;
